<template>
  <div class=" container-fluid crypto-wrapper p-5">
    <section class="row">
      <div class="col-sm-6 col-xl-3 mb-sm-2 mb-xl-0">
        <div class="card">
          <div class="card-header text-center">
            <img src="@assets/img/Bitcoin.svg" alt="" />
          </div>
          <div class="card-body">
            <p>Fit and Spot balance</p>
            <h5>0.00004826 <span>btc</span></h5>
            <p>$2.88</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3 mb-sm-2 mb-xl-0">
        <div class="card">
          <div class="card-header text-center">
            <img src="@assets/img/Bitcoin.svg" alt="" />
          </div>
          <div class="card-body">
            <p>Fit and Spot balance</p>
            <h5>0.00004826 <span>btc</span></h5>
            <p>$2.88</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3 mb-sm-2 mb-xl-0">
        <div class="card">
          <div class="card-header text-center">
            <img src="@assets/img/Bitcoin.svg" alt="" />
          </div>
          <div class="card-body">
            <p>Fit and Spot balance</p>
            <h5>0.00004826 <span>btc</span></h5>
            <p>$2.88</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3 mb-sm-2 mb-xl-0">
        <div class="card">
          <div class="card-header text-center">
            <img src="@assets/img/Bitcoin.svg" alt="" />
          </div>
          <div class="card-body">
            <p>Fit and Spot balance</p>
            <h5>0.00004826 <span>btc</span></h5>
            <p>$2.88</p>
          </div>
        </div>
      </div>
    </section>
    <section class="row">
      <div class="col">
        <div class="card mt-5 p-2 card_table">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h4 class="text-white">Balance details</h4>
              </div>
              <div class="row">
                <div class="col-lg-4 col-xl-6">
                  <input type="text" class="w-75 p-2" />
                </div>
                <div
                  class="col-lg-4 col-xl-3 mt-2 mt-lg-0 d-md-flex align-items-center justify-content-center"
                >
                  <input type="checkbox" id="balance" />
                  <label class="ms-2 text-white" for="balance"
                    >Hide Small Balances</label
                  >
                </div>
                <div
                  class="col-lg-4 col-xl-3 mt-2 mt-lg-0 d-md-flex align-items-center justify-content-center"
                >
                  <p class="text-warning m-0 text-decoration-underline">
                    Convert Small Balance to BNB
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body d-flex d-md-block">
            <div class="row flex-column flex-md-row name_column">
              <div class="col">
                <p>Coin</p>
              </div>
              <div class="col">
                <p>Total</p>
              </div>
              <div class="col">
                <p>Available</p>
              </div>
              <div class="col">
                <p>In Order</p>
              </div>
              <div class="col">
                <p>BTC Value</p>
              </div>
              <div class="col">
                <p>Action</p>
              </div>
            </div>
            <div
              class="row mt-md-3 ms-3 ms-md-0 flex-column flex-md-row column-info"
            >
              <div class="col">
                <div class="row align-items-center">
                  <div class="col-3 d-none d-md-block">
                    <div class="avatar"></div>
                  </div>
                  <div class="col col-9">
                    <p>BTC</p>
                    <p class="text-under">BMB</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <p>0.00382689</p>
              </div>
              <div class="col">
                <p>0.00382689</p>
              </div>
              <div class="col">
                <p>0.00000000</p>
              </div>
              <div class="col">
                <p>
                  0.00003633 <br />
                  <span class="ms-5"> =$2.17</span>
                </p>
              </div>

              <div class="col">
                <p id="action-text">Buy</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" src="./style.scss" scoped></style>
